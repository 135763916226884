/*
	Based off
	https://webdesign.tutsplus.com/build-a-custom-html-music-player-using-javascript-and-the-web-audio-api--cms-93300t
*/
customElements.define(
	'vca-audio-player',
	class extends HTMLElement {
		// Define attributes we want to observe
			static observedAttributes = ['playing'];

		/* HELPERS
		   https://www.trysmudford.com/blog/linear-interpolation-functions/
		   May be useful for things like translating timeline progress position and/or CSS custom property updates
		*/

		/**
		 * Returns the value between two provided numbers at a specified decimal midpoint
		 * @param {number} x The low bound
		 * @param {number} y The high bound
		 * @param {number} a The target decimal location between X and Y
		 * @returns {number} The value at position A, between X and Y
		 * @example
		 * lerp(20, 80, 0.5); // returns 50
		 */
		lerp = (x, y, a) => x * (1 - a) + y * a;

		/**
		 * If your number falls within the bounds of the min & max, it’ll return it. If not, it’ll return either the minimum it’s smaller, or the maximum if it’s bigger
		 * @param {number} a The number to be tested
		 * @param {number} min The minimum allowed
		 * @param {number} max The maximum allowed
		 * @returns {number} A if its value lies between X and Y, X if A is lower than X, Y if A is higher than Y
		 * @example
		 * clamp(20, 80, 0.5); // returns 20
		 */
		clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));

		/**
		 * Returns the decimal position of a given value between two provided endpoints (clamped for safety)
		 * @param {number} x The low bound
		 * @param {number} y The high bound
		 * @param {number} a The value between X and Y
		 * @returns {number} The decimal position of A, between X and Y
		 * @example
		 * invlerp(20, 80, 50); // returns 0.5
		 */
		invlerp = (x, y, a) => clamp((a - x) / (y - x));

		/**
		 * Converts a value from one data range to another
		 * @param {number} low1 The low bound of range 1
		 * @param {number} high1 The high bound of range 1
		 * @param {number} low2 The low bound of range 2
		 * @param {number} high2 The high bound of range 2
		 * @param {number} target1 The target value between low1 and high1
		 * @returns {number} The value between low2 and high2 at the equivalent position
		 * @example
		 * range(10, 100, 2000, 20000, 50); // returns 10000
		 */
		range   = (low1, high1, low2, high2, target1) => lerp(low2, high2, invlerp(low1, high1, target1));

		/**
		 * Writes times to the appropriate DOM elements
		 */
		setTimes() {
			this.elapsed.textContent = new Date(this.audioElement.currentTime * 1000)
				.toISOString()
				.substr(12, 7);
			this.trackLength.textContent = new Date(this.audioElement.duration * 1000)
				.toISOString()
				.substr(12, 7)
		}

		/**
		 * Update player timeline progress visually
		 */
		progressUpdate() {
			this.rangeInput.value = this.audioElement.currentTime;
		}

		/**
		 * Allow scrubbing of the "timeline" to change the player head position
		 */
		scrub() {
			this.audioElement.currentTime = this.rangeInput.value;
		}

		/**
		 * Logic for setting up the web component, which has to be called after some prep-work by initialise()
		 */
		setup () {
			this.audioContext  = new AudioContext();
			this.gainNode      = this.audioContext.createGain();
			this.track         = this.audioContext.createMediaElementSource(this.audioElement);
			this.mouseDown     = false;

			this.vcaHtml = `<div>
				<div class="player">
					<button role="button" class="playPause" data-state="paused">
						<span class="uc_hide-visually">Play</span>
					</button>

					<div class="trackTime">
						<div class="elapsed">0:00</div>
						<div class="trackLength"></div>
					</div>

					<div class="rangeBar">
						<label>
							<span class="uc_hide-visually">Progress</span>
							<input
								type="range"
								min="0"
								value="0"
								step="0.01"
							/>
						</label>
					</div>

					<div class="volumeBar">
						<label>
							<span class="uc_hide-visually">Volume</span>
							<input
								type="range"
								class="volumeControl"
								min="0"
								max="1"
								value="1"
								step="0.01"
							/>
						</label>
					</div>
				</div>

				<div class="download">
					<a href="${this.audioElement.getAttribute('src')}" download>
						<span class="uc_hide-visually">Download Audio</span>
					</a>
				</div>
			</div>`;

			// Add the markup to the page
				this.insertAdjacentHTML('afterbegin', this.vcaHtml);

			// Now store more DOM nodes for convenience AFTER they've been attached to the DOM
				this.volumeControl  = this.querySelector('.volumeControl');
				this.elapsed        = this.querySelector('.elapsed');
				this.trackLength    = this.querySelector('.trackLength');
				this.playButton     = this.querySelector('.playPause');
				this.playButtonText = this.playButton.querySelector('span');
				this.rangeInput     = this.querySelector('.rangeBar input');

			// Set the max value in seconds for the progress bar
				this.rangeInput.setAttribute('max', this.audioElement.duration);

			// Hook up event listener and actions for play/pause button
				this.playButton.addEventListener('click', (e) => {
					/* The button jumps the page on first click and I do not know why */
					e.preventDefault();

					if (this.audioContext.state === "suspended") {
						this.audioContext.resume();
					}

					if (this.playButton.dataset.state === "paused") {
						this.audioElement.play();

						this.playButtonText.textContent = "Pause";
						this.playButton.dataset.state   = "playing";
					} else {
						this.audioElement.pause();
						this.playButtonText.textContent = "Play";
						this.playButton.dataset.state   = "paused";
					}
				});

			// Hook up event listener and actions for volume control
				this.volumeControl.addEventListener("change", () => {
					this.gainNode.gain.value = this.volumeControl.value;
				});
				this.track.connect( this.gainNode ).connect( this.audioContext.destination );

			// Hook up event listener for when the audio track gets to the end
				this.audioElement.addEventListener("ended", () => {
					this.playButton.dataset.state   = "paused";
					this.playButtonText.textContent = "Play";
					this.audioElement.currentTime   = 0;
				});

			// Hook up event listener so that we can update our UI while the track is playing
				this.audioElement.addEventListener(
					"timeupdate",
					() => {
						this.progressUpdate();
						this.setTimes();
					}
				);

			// Hook up the rangeInput so that when it's dragged it effects the audio position
				this.rangeInput.addEventListener(
					"click",
					() => {
						this.scrub();
					}
				);
				this.rangeInput.addEventListener(
					"mousemove",
					() => {
						if(this.mousedown) {
							this.scrub;
						}
					}
				);
				this.rangeInput.addEventListener(
					"mousedown",
					() => {
						this.mousedown = true;
					}
				);
				this.rangeInput.addEventListener(
					"mouseup",
					() => {
						this.mousedown = false
					}
				);

			// Populate the time readouts based on the loaded audio file's metadata
				this.setTimes();

			// Flag that we've run this function and everything is already initialised
				this._setupRunning  = false;
				this._setupComplete = true;
		}

		/**
		 * We need to wait for the `<audio>` element's metadata to have been loaded by the browser before we run the rest of the setup, as our functionality is dependant on that information. Therefor we have this little function which will wait for that event to fire, before doing the rest of the setup.
		 */
		initialise () {
			// Abort the function if it has been run before
				if (this._setupComplete) return;
			// Abort this function if it's _currently_ running as well (because we're waiting on the audio element's metadata this might happen?)
				if (this._setupRunning) return;

			// We need to wait for the <audio> element's metadata to have been loaded by the browser before we run the rest of the setup, as it is dependant on that information.
				this._setupRunning = true;
				this.audioElement  = this.querySelector('audio');

				/* Defend against the browser having _already_ loaded the metadata before executing this script... */
				if(this.audioElement.readyState > 0) {
					this.setup();
				}
				else {
					this.audioElement.addEventListener("loadedmetadata", ()=>{
						this.setup();
					});
				}
		}

		/**
		 * The class constructor method.
		 * This is only ever called once per instance.
		 * At this point you can't add Nodes inside the normal DOM, and you can't add or set an attribute either; because it's not connected to the DOM yet.
		 */
		constructor() {
			// Inherit everything from the parent HTMLElement class
				super();

			// Now we can do setup stuff that only needs running once
		}

		/**
		 * Run methods when an instance of this custom element attaches to the DOM
		 * i.e., if some "ajax" JS adds a <vca-audio-player /> element into the page, it will work
		 */
		connectedCallback () {
			// Set up the web component if it hasn't already been set up
				this.initialise();
		}

		/**
		 * When an instance is removed from the DOM this event allows us to clean up things previously done in connectedCallback.
		 */
		disconnectedCallback() {
			// e.g., emptying localStorage or something that might otherwise persist needlessly
		}
	}
)
